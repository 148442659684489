<template>
  <div>
    <toggleSwitch
      :title="$t('design.info.startup.text')"
      :help="''"
      :value="data"
      :callback="setValue"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.getValue()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setValue(ret) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.info.onStart = ret;
      this.$store.commit("business/prefetch", data);
    },
    getValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (typeof data.info === "undefined" || !data.info) {
        return false;
      }
      return data.info.onStart;
    }
  }
};
</script>
<style lang="sass" scoped></style>
